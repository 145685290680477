const cities = [
    // Tier 1 Cities
    'Mumbai',
    'Delhi',
    'Bangalore',
    'Kolkata',
    'Chennai',
    'Hyderabad',
    'Pune',
    'Ahmedabad',
    
    // Tier 2 Cities
    'Surat',
    'Jaipur',
    'Kanpur',
    'Nagpur',
    'Lucknow',
    'Indore',
    'Patna',
    'Bhopal',
    'Ludhiana',
    'Agra',
    
    // Tier 3 Cities
    'Madurai',
    'Kota',
    'Salem',
    'Tiruchirapalli',
    'Bhubaneswar',
    'Aligarh',
    'Bareilly',
    'Moradabad',
    'Vijayawada',
    'Jodhpur',
    'Raipur',
    'Guwahati',
    'Coimbatore',
    'Kochi',
    'Mysore',
    'Kozhikode',
    'Dehradun',
    'Jamnagar',
    'Nashik',
    'Rajkot',
    'Dhanbad',
    'Gorakhpur',
    'Amravati',
    'Jalandhar',
    'Guntur',
    'Saharanpur',
    'Warangal',
    'Bihar Sharif',
    'Shillong',
    'Nellore',
    'Gulbarga',
    'New Delhi',
    'Chandigarh',
    'Noida',
    'Gurgaon',
    'Faridabad',
    'Ghaziabad',
    'Greater Noida',
    'Kohima',
    'Imphal',
    'Itanagar',
    'Aizawl',
    'Agartala',
    'Kavaratti',
    'Puducherry'
  ];
  
  export default cities;
  