// predefinedLoanAmounts.js
const predefinedLoanAmounts = [
    '1 Lakh',
    '2 Lakhs',
    '3 Lakhs',
    '4 Lakhs',
    '5 Lakhs',
    '6 Lakhs',
    '7 Lakhs',
    '8 Lakhs',
    '9 Lakhs',
    '10 Lakhs',
    '11 Lakhs',
    '12 Lakhs',
    '13 Lakhs',
    '14 Lakhs',
    '15 Lakhs',
    '16 Lakhs',
    '17 Lakhs',
    '18 Lakhs',
    '19 Lakhs',
    '20 Lakhs',
    '21 Lakhs',
    '22 Lakhs',
    '23 Lakhs',
    '24 Lakhs',
    '25 Lakhs',
    '26 Lakhs',
    '27 Lakhs',
    '28 Lakhs',
    '29 Lakhs',
    '30 Lakhs',
    '31 Lakhs',
    '32 Lakhs',
    '33 Lakhs',
    '34 Lakhs',
    '35 Lakhs',
    '36 Lakhs',
    '37 Lakhs',
    '38 Lakhs',
    '39 Lakhs',
    '40 Lakhs',
    '41 Lakhs',
    '42 Lakhs',
    '43 Lakhs',
    '44 Lakhs',
    '45 Lakhs',
    '46 Lakhs',
    '47 Lakhs',
    '48 Lakhs',
    '49 Lakhs',
    '50 Lakhs',
    '51 Lakhs',
    '52 Lakhs',
    '53 Lakhs',
    '54 Lakhs',
    '55 Lakhs',
    '56 Lakhs',
    '57 Lakhs',
    '58 Lakhs',
    '59 Lakhs',
    '60 Lakhs',
    '61 Lakhs',
    '62 Lakhs',
    '63 Lakhs',
    '64 Lakhs',
    '65 Lakhs',
    '66 Lakhs',
    '67 Lakhs',
    '68 Lakhs',
    '69 Lakhs',
    '70 Lakhs',
    '71 Lakhs',
    '72 Lakhs',
    '73 Lakhs',
    '74 Lakhs',
    '75 Lakhs',
    '76 Lakhs',
    '77 Lakhs',
    '78 Lakhs',
    '79 Lakhs',
    '80 Lakhs',
    '81 Lakhs',
    '82 Lakhs',
    '83 Lakhs',
    '84 Lakhs',
    '85 Lakhs',
    '86 Lakhs',
    '87 Lakhs',
    '88 Lakhs',
    '89 Lakhs',
    '90 Lakhs',
    '91 Lakhs',
    '92 Lakhs',
    '93 Lakhs',
    '94 Lakhs',
    '95 Lakhs',
    '96 Lakhs',
    '97 Lakhs',
    '98 Lakhs',
    '99 Lakhs',
    '1 Crore'
];

export default predefinedLoanAmounts;
